import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPaymentMethods(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axiosIns.get('/payment_method/list', {params:queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
        })
    },
    fetchPaymentMethod(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`/payment_method/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    addPaymentMethod(ctx, data) {
        return new Promise((resolve, reject) => {
            axiosIns.post('/payment_method', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    deletePaymentMethod(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns.delete('/payment_method', {params:data})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
