import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function usePaymentMethodsList() {

    //User toast
    const toast = useToast()

    const refPaymentMethodListTable = ref(null)

    const tableColumns = [
        {key: 'method_name', label:'支付方法', sortable: true},
        {key: 'description', label: '描述'},
        {key: 'is_active', label:'狀態', sortable: true},
        {key: 'action', label: '操作'}
    ]

    const perPage = ref(10)
    const totalPaymentMethods = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(false)

    const dataMeta = computed(() => {
        const localItemsCount = refPaymentMethodListTable.value ? refPaymentMethodListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalPaymentMethods.value,
        }
    })

    const refetchData = () => {
        refPaymentMethodListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
        refetchData()
    })

    const fetchPaymentMethods = (ctx, callback) => {
        store.dispatch('setting-paymentmethod/fetchPaymentMethods',{
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
        })
        .then(response =>{
            const {paymentmethods, total} = response.data
            callback(paymentmethods)
            totalPaymentMethods.value = total
        
        })
        .catch((error) => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching payment methods list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                }
            })
        })
    }

    return {
        fetchPaymentMethods,
        tableColumns,
        perPage,
        currentPage,
        totalPaymentMethods,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refPaymentMethodListTable,
        ability,
        refetchData
    }

}